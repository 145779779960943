@import url('https://fonts.googleapis.com/css?family=Warnes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');



.App {
  text-align: center;
  justify-content: center;
  display: flex;
  background-color: #0D0E30;
  position: fixed;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}

.title {
	font-size: 3vw;
	font-weight: 500;
	color: #fff;
	margin-top: 4vw;
	position: relative;
	font-family: 'Comfortaa', cursive;
	text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6, 0 0 25px #0073e6;
}


.tutorial {
	display: flex;
	margin-top: 1vw;
	justify-content: center;
	align-items: center;

}

.tutorial-letters {
	color: #fff;
	font-size: 1vw;
	font-family: 'Comfortaa', cursive;
}

.player-color {
	height: 1vw;
	width: 1vw;
	border-radius: 100px;
	margin-left: 0.3vw;

}

.MuiSelect-icon {
	height: 1vw !important;
	width: 1vw !important;
	top: auto !important;
	color: white !important;
	right: 0.5vw !important;
}

.MuiSelect-outlined {
	display: flex !important;
}

.MuiInputBase-input {
	height: auto !important;
	min-height: auto !important;
	color: white !important;
	font-family: 'Comfortaa', cursive !important;
	padding: 0.5vw 0.5vw !important;
}

.MuiOutlinedInput-notchedOutline {
	border: none !important;
}

.MuiInputBase-root {
	border: 0.5px solid #fff;
	/*padding-left: 0.5vw;*/
}

.MuiInputBase-root:hover {
	outline: none !important;
}

.MuiListItem-root {
	font-family: 'Comfortaa', cursive !important;
}

#player-color-1, #player-color-2, #player-color-3, #player-color-4 {
	height: 1vw;
	width: 1vw;
	border-radius: 100px;
}


#player-color-1 {
	background-color: #7FFF00;
}

#player-color-2 {
	background-color: #fc6e22;
}

#player-color-3 {
	background-color: #01ffff;
}

#player-color-4 {
	background-color: #01ffc3;
}

.contact {
	color: white;
	font-family: 'Comfortaa', cursive !important;
	margin-bottom: 2vw;
}



