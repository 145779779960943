.column {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
}

.column:hover {
	box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6;
	border-radius: 100px;

}