.game-board {
/*	height: 530px;
    width: 590px;*/
    width: 35%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    position: relative;
}

.game-board:after {
	content: "";
	display: block;
	padding-bottom:  85%;
	position: relative;
}

.game-container {
	width: 100%;
	height: 100%;
	/*justify-content: center;*/
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
}

.column-full-message {
	margin-bottom: 1vw;
	color: #fff;
	font-family: 'Comfortaa', cursive;
	font-size: 1vw;
	font-weight: 300;
}

.winner-message-1, .winner-message-2, .winner-message-3 {
	font-size: 1.5vw;
	font-weight: 1000;
	margin-bottom: 1vw;
	font-family: 'Comfortaa', cursive;
	position: relative;
}

.winner-message-2 {
	color: #ff2079;
}

.winner-message-3 {
	color: #fff;
}

.restart-button {
	margin-top: 2vw;
	margin-bottom: 2vw;
	width: 5vw;
	font-size: 0.9vw;
	border-radius: 1vw;
	color: #fff;
	font-family: 'Comfortaa', cursive;
	background-color: #0D0E30;
	border: none;
	box-shadow: 0 0 5px #0073e6, 0 0 10px #0073e6, 0 0 15px #0073e6;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

}

.restart-button:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.restart-button:hover {
	box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6, 0 0 25px #0073e6;
}

.restart-button:focus {
	outline: 0;
}

.first-player-option, .second-player-option {
	color: #0D0E30 !important;
	font-family: 'Comfortaa', cursive !important;
	font-weight: 900 !important;
	border: 1px solid #0D0E30 !important;
}

.first-player-option:hover, .second-player-option:hover {
	box-shadow: 0 0 5px #0073e6, 0 0 10px #0073e6, 0 0 15px #0073e6 !important;
}

#player-order-dialog-title .MuiTypography-root {
	color: #0D0E30 !important;
	font-family: 'Comfortaa', cursive !important;
	font-weight: 900 !important;
}

#player-order-dialog-description {
	color: #0D0E30 !important;
	font-family: 'Comfortaa', cursive !important;
	font-weight: 900 !important;
}

#player-order-dialog .MuiDialogActions-root{
	justify-content: center !important;
	padding-bottom: 1.5vw !important ;

}

#player-order-dialog .MuiDialogContent-root {
	display: flex;
	justify-content: center !important;	
	padding: 0vw 0.1vw !important;

}


