@import url(https://fonts.googleapis.com/css?family=Warnes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rajdhani&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  justify-content: center;
  display: flex;
  background-color: #0D0E30;
  position: fixed;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}

.title {
	font-size: 3vw;
	font-weight: 500;
	color: #fff;
	margin-top: 4vw;
	position: relative;
	font-family: 'Comfortaa', cursive;
	text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6, 0 0 25px #0073e6;
}


.tutorial {
	display: flex;
	margin-top: 1vw;
	justify-content: center;
	align-items: center;

}

.tutorial-letters {
	color: #fff;
	font-size: 1vw;
	font-family: 'Comfortaa', cursive;
}

.player-color {
	height: 1vw;
	width: 1vw;
	border-radius: 100px;
	margin-left: 0.3vw;

}

.MuiSelect-icon {
	height: 1vw !important;
	width: 1vw !important;
	top: auto !important;
	color: white !important;
	right: 0.5vw !important;
}

.MuiSelect-outlined {
	display: flex !important;
}

.MuiInputBase-input {
	height: auto !important;
	min-height: auto !important;
	color: white !important;
	font-family: 'Comfortaa', cursive !important;
	padding: 0.5vw 0.5vw !important;
}

.MuiOutlinedInput-notchedOutline {
	border: none !important;
}

.MuiInputBase-root {
	border: 0.5px solid #fff;
	/*padding-left: 0.5vw;*/
}

.MuiInputBase-root:hover {
	outline: none !important;
}

.MuiListItem-root {
	font-family: 'Comfortaa', cursive !important;
}

#player-color-1, #player-color-2, #player-color-3, #player-color-4 {
	height: 1vw;
	width: 1vw;
	border-radius: 100px;
}


#player-color-1 {
	background-color: #7FFF00;
}

#player-color-2 {
	background-color: #fc6e22;
}

#player-color-3 {
	background-color: #01ffff;
}

#player-color-4 {
	background-color: #01ffc3;
}

.contact {
	color: white;
	font-family: 'Comfortaa', cursive !important;
	margin-bottom: 2vw;
}




.unselected-slot {
    background-color: white;
}

.player2-slot {
    background-color: #ff2079;
}


.unselected-slot, .player1-slot, .player2-slot {
	border-radius: 200px !important;
	position: relative;
	flex: 1 1 auto;
	margin: 2px !important;
		/*margin: 2px;*/
}



.column {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
}

.column:hover {
	box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6;
	border-radius: 100px;

}
.game-board {
/*	height: 530px;
    width: 590px;*/
    width: 35%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    position: relative;
}

.game-board:after {
	content: "";
	display: block;
	padding-bottom:  85%;
	position: relative;
}

.game-container {
	width: 100%;
	height: 100%;
	/*justify-content: center;*/
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
}

.column-full-message {
	margin-bottom: 1vw;
	color: #fff;
	font-family: 'Comfortaa', cursive;
	font-size: 1vw;
	font-weight: 300;
}

.winner-message-1, .winner-message-2, .winner-message-3 {
	font-size: 1.5vw;
	font-weight: 1000;
	margin-bottom: 1vw;
	font-family: 'Comfortaa', cursive;
	position: relative;
}

.winner-message-2 {
	color: #ff2079;
}

.winner-message-3 {
	color: #fff;
}

.restart-button {
	margin-top: 2vw;
	margin-bottom: 2vw;
	width: 5vw;
	font-size: 0.9vw;
	border-radius: 1vw;
	color: #fff;
	font-family: 'Comfortaa', cursive;
	background-color: #0D0E30;
	border: none;
	box-shadow: 0 0 5px #0073e6, 0 0 10px #0073e6, 0 0 15px #0073e6;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

}

.restart-button:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.restart-button:hover {
	box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6, 0 0 25px #0073e6;
}

.restart-button:focus {
	outline: 0;
}

.first-player-option, .second-player-option {
	color: #0D0E30 !important;
	font-family: 'Comfortaa', cursive !important;
	font-weight: 900 !important;
	border: 1px solid #0D0E30 !important;
}

.first-player-option:hover, .second-player-option:hover {
	box-shadow: 0 0 5px #0073e6, 0 0 10px #0073e6, 0 0 15px #0073e6 !important;
}

#player-order-dialog-title .MuiTypography-root {
	color: #0D0E30 !important;
	font-family: 'Comfortaa', cursive !important;
	font-weight: 900 !important;
}

#player-order-dialog-description {
	color: #0D0E30 !important;
	font-family: 'Comfortaa', cursive !important;
	font-weight: 900 !important;
}

#player-order-dialog .MuiDialogActions-root{
	justify-content: center !important;
	padding-bottom: 1.5vw !important ;

}

#player-order-dialog .MuiDialogContent-root {
	display: flex;
	justify-content: center !important;	
	padding: 0vw 0.1vw !important;

}



