.unselected-slot {
    background-color: white;
}

.player2-slot {
    background-color: #ff2079;
}


.unselected-slot, .player1-slot, .player2-slot {
	border-radius: 200px !important;
	position: relative;
	flex: 1 1 auto;
	margin: 2px !important;
		/*margin: 2px;*/
}


